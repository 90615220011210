





































































































import { Component, Watch } from "vue-property-decorator";
import TextDecimal from "@/components/TextDecimal.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import FscCard from "@/components/Card/FscCard.vue";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AutomaticPaymentTriggersTable from "@/views/AutomaticPaymentsOverview/AutomaticPaymentTriggersTable.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import { IAutomaticPaymentTrigger } from "@/interfaces/IAutomaticPaymentTrigger";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import PaymentHeader from "@/views/AutomaticPaymentsOverview/PaymentHeader.vue";
import LicenseClassRequestMixin from "@/mixins/Request/LicenseClassRequestMixin";
import FscMultiselect from "@/components/FscMultiselect.vue";
import Table from "@/components/Table.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import AutomaticPaymentCreateForm from "@/views/AutomaticPaymentsOverview/AutomaticPaymentCreateForm.vue";
import AutomaticPaymentEditForm from "@/views/AutomaticPaymentsOverview/AutomaticPaymentEditForm.vue";
import DeleteModal from "@/components/Modal/DeleteModal.vue";

const AutomaticPaymentTriggerModule = namespace("automatic-payment-trigger");
const AutomaticPaymentEventModule = namespace("automatic-payment-event");
const LicenseClassModule = namespace("license-class");

const automaticPaymentEventResource = "/automatic-payment-events";
const automaticPaymentTriggerResource = "/automatic-payment-triggers";

@Component({
  components: {
    PaymentHeader,
    AutomaticPaymentTriggersTable,
    SaveButton,
    AbortButton,
    HintMessage,
    FscCard,
    Hint,
    TextDecimal,
    FscMultiselect,
    Table,
    CreateButton,
    EditButton,
    RemoveButton,
    AutomaticPaymentCreateForm,
    AutomaticPaymentEditForm,
    DeleteModal,
  },
})
export default class AutomaticPaymentTriggers extends mixins(TabMixin, LicenseClassRequestMixin) {
  public name = "AutomaticPaymentTriggers";

  @AutomaticPaymentTriggerModule.Action("findAll")
  public findAllAutomaticPaymentTriggers: any;

  @AutomaticPaymentTriggerModule.Action("create")
  public createAutomaticPaymentTriggers: any;

  @AutomaticPaymentTriggerModule.Action("update")
  public updateAutomaticPaymentTriggers: any;

  @AutomaticPaymentTriggerModule.Getter("getDataList")
  public getAutomaticPaymentTriggers!: Array<IAutomaticPaymentTrigger>;

  @AutomaticPaymentTriggerModule.Getter("getSuccess")
  public successAutomaticPaymentTriggers!: any;

  @AutomaticPaymentTriggerModule.Getter("getError")
  public error!: any;

  @AutomaticPaymentTriggerModule.Getter("getIsLoading")
  public isLoadingAutomaticPaymentTriggers!: any;

  @AutomaticPaymentTriggerModule.Action("del")
  public deleteAutomaticPaymentTriggers: any;

  @AutomaticPaymentEventModule.Action("findAll")
  public findAllAutomaticPaymentEvents: any;

  @AutomaticPaymentEventModule.Getter("getDataList")
  public getAutomaticPaymentEvents!: Array<IBasicNamedDTO>;

  @LicenseClassModule.Action("findAll")
  public findAllLicenseClasses: any;

  @LicenseClassModule.Getter("getDataList")
  public allLicenseClasses: any;

  public fields = [
    {
      key: "automaticPaymentEvent",
      label: this.$t("general.automatic_payment_event"),
      sortable: false,
    },
    {
      key: "amountInEur",
      label: this.$t("students.amount"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$tc("general.license_class"),
      sortable: false,
    },
  ];

  public automaticPaymentSelectedRow: any = null;
  public visibleCreateForm = false;
  public visibleEditForm = false;

  public deleteModalId = "delete-modal-id";

  public mounted(): void {
    // this.findAllBaseLicenseClass();
    this.findAllLicenseClasses({
      resource: "license-classes/driving-school/current",
    });
    this.findAllAutomaticPaymentTriggers({
      resource: `${automaticPaymentTriggerResource}/current`,
    });

    this.findAllAutomaticPaymentEvents({
      resource: automaticPaymentEventResource,
    });
  }

  protected onAutomaticPaymentEventRowClicked(row: any): void {
    this.automaticPaymentSelectedRow = row;
    this.visibleCreateForm = false;
    this.visibleEditForm = true;
  }

  protected onCreateAutomaticPayment(): void {
    this.visibleEditForm = false;
    this.automaticPaymentSelectedRow = null;
    this.visibleCreateForm = true;
  }

  protected onAbortCreateAutomaticPayment() {
    this.visibleCreateForm = false;
  }

  protected onAbortEditAutomaticPayment() {
    this.visibleEditForm = false;
    this.automaticPaymentSelectedRow = null;
  }

  protected async onSubmitCreate(data: any) {
    if (data) {
      await this.createAutomaticPaymentTriggers({
        resource: "automatic-payment-triggers",
        data: data,
      });
    }
    if (this.successAutomaticPaymentTriggers) {
      await this.findAllAutomaticPaymentTriggers({
        resource: `${automaticPaymentTriggerResource}/current`,
      });
      this.visibleCreateForm = false;
      this.automaticPaymentSelectedRow = null;
    }
  }

  protected async onSubmitEdit(data: any) {
    if (data) {
      await this.updateAutomaticPaymentTriggers({
        id: data.id,
        resource: "automatic-payment-triggers",
        data: data,
      });
    }
    if (this.successAutomaticPaymentTriggers) {
      await this.findAllAutomaticPaymentTriggers({
        resource: `${automaticPaymentTriggerResource}/current`,
      });
      this.visibleEditForm = false;
      this.automaticPaymentSelectedRow = null;
    }
  }

  protected async onDeleteAutomaticPayment() {
    if (this.automaticPaymentSelectedRow) {
      const id = this.automaticPaymentSelectedRow ? this.automaticPaymentSelectedRow.id : null;
      await this.deleteAutomaticPaymentTriggers({
        resource: `${automaticPaymentTriggerResource}`,
        id: id,
      });
      this.automaticPaymentSelectedRow = null;
      this.visibleEditForm = false;
      await this.findAllAutomaticPaymentTriggers({
        resource: `${automaticPaymentTriggerResource}/current`,
      });
    }
  }

  public onDeleteAutomaticPaymentModal(): void {
    if (!this.automaticPaymentSelectedRow?.id) return;
    this.$bvModal.show(this.deleteModalId);
  }

  // public selectedLicenseClass: any = null;
  //
  // public disabledSubmit = true;
  //
  // protected automaticPaymentTriggers: Array<IAutomaticPaymentTrigger> = [
  //   {
  //     automaticPaymentEvent: undefined,
  //     amountInEur: undefined,
  //     licenseClass: undefined,
  //   },
  // ];
  //
  // protected allPaymentEvents: Array<IAutomaticPaymentTrigger> = [
  //   {
  //     automaticPaymentEvent: undefined,
  //     amountInEur: undefined,
  //     licenseClass: undefined,
  //   },
  // ];
  //
  // protected onAbort(): void {
  //   this.closeCurrentTab();
  // }
  //
  // protected async onSubmit(): Promise<void> {
  //   if (this.automaticPaymentTriggers.length > 0 && this.automaticPaymentTriggers[0].licenseClass === undefined) {
  //     this.automaticPaymentTriggers[0].licenseClass = this.selectedLicenseClass;
  //   }
  //   this.allPaymentEvents.map((payment: any, index: number) => {
  //     if (this.automaticPaymentTriggers.includes(payment)) {
  //       console.log(index);
  //     } else {
  //       this.automaticPaymentTriggers.push(payment);
  //     }
  //   });
  //   await this.createAutomaticPaymentTriggers({
  //     resource: `${automaticPaymentTriggerResource}/current`,
  //     data: this.automaticPaymentTriggers,
  //   });
  //
  //   if (this.successAutomaticPaymentTriggers) {
  //     this.closeCurrentTab();
  //   }
  // }
  //
  // protected onDeleteAutomaticPaymentTriggers(index: number): void {
  //   const deleted = this.automaticPaymentTriggers.filter((item: any) => item.id !== index);
  //   this.automaticPaymentTriggers = deleted;
  //   const deletedAll = this.allPaymentEvents.filter((item: any) => item.id !== index);
  //   this.allPaymentEvents = deletedAll;
  // }
  //
  // @Watch("getAutomaticPaymentTriggers")
  // protected getAutomaticPaymentTriggersOnChange(automaticPaymentTriggers: Array<IAutomaticPaymentTrigger>): void {
  //   if (this.getAutomaticPaymentTriggers.length) {
  //     this.allPaymentEvents = automaticPaymentTriggers;
  //   } else {
  //     this.allPaymentEvents = [];
  //   }
  // }
  //
  // @Watch("selectedLicenseClass")
  // public onSelectedLicenseClassChange(licenseClass: any) {
  //   if (licenseClass && this.getAutomaticPaymentTriggers.length) {
  //     const filtered = this.getAutomaticPaymentTriggers.filter((payment: any) => payment.licenseClass === licenseClass);
  //     if (filtered.length > 0) {
  //       this.automaticPaymentTriggers = filtered;
  //     } else {
  //       this.automaticPaymentTriggers = [
  //         {
  //           automaticPaymentEvent: undefined,
  //           amountInEur: undefined,
  //           licenseClass: undefined,
  //         },
  //       ];
  //     }
  //   }
  // }
  //
  // @Watch("automaticPaymentTriggers", { deep: true, immediate: true })
  // public onAutomaticPaymentChange(payment: any) {
  //   payment.map((pay: any) => {
  //     if (!pay.amountInEur || pay.automaticPaymentEvent === undefined) {
  //       this.disabledSubmit = true;
  //     } else {
  //       this.disabledSubmit = false;
  //     }
  //   });
  // }
  //
  // protected onAddAutomaticPaymentTriggers(): void {
  //   this.automaticPaymentTriggers.push({
  //     automaticPaymentEvent: undefined,
  //     amountInEur: undefined,
  //     licenseClass: this.selectedLicenseClass,
  //   });
  // }
  //
  // @Watch("error")
  // public onError(error: any) {
  //   if (error) {
  //     this.selectedLicenseClass = null;
  //   }
  // }
}
